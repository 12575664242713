type SiteEnv = "caddy" | "local" | "development" | "staging" | "production";
type EnvVar =
  | "PG_API_HOST_URL"
  | "GOMONO_HOST_URL"
  | "FINISH_LATER_URL"
  | "FIRM_LOCATOR_URL"
  | "GEO_LOCATOR_URL"
  | "BUSINESS_HOURS_URL";

const applicationConfiguration: Record<SiteEnv, Record<EnvVar, string>> = {
  caddy: {
    PG_API_HOST_URL: "https://api.atticus-staging.com",
    GOMONO_HOST_URL: "http://localhost",
    FINISH_LATER_URL: "http://localhost",
    FIRM_LOCATOR_URL: "http://localhost",
    GEO_LOCATOR_URL: "http://localhost",
    BUSINESS_HOURS_URL: "http://localhost",
  },
  local: {
    PG_API_HOST_URL: "https://api.atticus-staging.com",
    GOMONO_HOST_URL: "http://localhost:5003",
    FINISH_LATER_URL: "http://localhost:5011",
    FIRM_LOCATOR_URL: "http://localhost:5013",
    GEO_LOCATOR_URL: "http://localhost:5015",
    BUSINESS_HOURS_URL: "http://localhost:5020",
  },
  development: {
    PG_API_HOST_URL: "https://api.atticus-staging.com",
    GOMONO_HOST_URL: "https://api.atticus-staging.com",
    FINISH_LATER_URL: "https://api.atticus-staging.com",
    FIRM_LOCATOR_URL: "https://api.atticus-staging.com",
    GEO_LOCATOR_URL: "https://api.atticus-staging.com",
    BUSINESS_HOURS_URL: "https://api.atticus-staging.com",
  },
  staging: {
    PG_API_HOST_URL: "https://api.atticus-staging.com",
    GOMONO_HOST_URL: "https://api.atticus-staging.com",
    FINISH_LATER_URL: "https://api.atticus-staging.com",
    FIRM_LOCATOR_URL: "https://api.atticus-staging.com",
    GEO_LOCATOR_URL: "https://api.atticus-staging.com",
    BUSINESS_HOURS_URL: "https://api.atticus-staging.com",
  },
  production: {
    PG_API_HOST_URL: "https://api.atticus.com",
    GOMONO_HOST_URL: "https://api.atticus.com",
    FINISH_LATER_URL: "https://api.atticus.com",
    FIRM_LOCATOR_URL: "https://api.atticus.com",
    GEO_LOCATOR_URL: "https://api.atticus.com",
    BUSINESS_HOURS_URL: "https://api.atticus.com",
  },
};

export const getConfigVar = (varName: EnvVar): string => {
  const hostName: string | boolean =
    typeof window !== "undefined" &&
    window.location &&
    window.location.hostname;

  const appEnv: string | undefined =
    process && process.env && process.env.APP_ENV;

  let env: SiteEnv = "local";
  if (
    (hostName && hostName.includes(".dev.atticus.com")) ||
    appEnv === "development"
  ) {
    env = "development";
  } else if (
    hostName === "www.atticus-staging.com" ||
    appEnv === "staging" ||
    appEnv === "staging-test"
  ) {
    env = "staging";
  } else if (
    hostName === "www.atticus.com" ||
    appEnv === "production" ||
    appEnv === "production-test"
  ) {
    env = "production";
  } else if (appEnv === "caddy") {
    env = "caddy";
  }

  return applicationConfiguration[env][varName];
};
